
import Toolbar from 'primevue/toolbar';
import FileUpload from 'primevue/fileupload';

import { ImgPreview, Loader, Icon } from '../../components/ui';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Toolbar,
    FileUpload,
    ImgPreview,
    Loader
    // Icon,
  },
  setup() {
    const { settingsStore, settingsStoreState } = useStores();

    const banners = settingsStoreState.banners;

    const { t } = useI18n();
    const toast = useToast();

    async function upload(params) {
      const body = {
        photo: params.files[0]
      };

      settingsStore.saveChanges('banners', 'companyBanners', body, toast, t);
    }

    async function deleteBg(params) {
      settingsStore.delete('banners', 'companyBanner', params);
    }

    return {
      settingsStoreState,

      banners,
      upload,
      deleteBg
    };
  }
};
