import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "_heading-2" }
const _hoisted_2 = { class: "grid mt-4 _relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_ImgPreview = _resolveComponent("ImgPreview")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toolbar, null, {
      left: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('settings_page.title.banner')), 1)
      ]),
      right: _withCtx(() => [
        _createVNode(_component_FileUpload, {
          name: "file",
          mode: "basic",
          auto: true,
          customUpload: true,
          onUploader: $setup.upload,
          maxFileSize: 8000000,
          chooseLabel: _ctx.$t('settings_page.add_logo')
        }, null, 8, ["onUploader", "chooseLabel"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.banners.data, (item) => {
        return (_openBlock(), _createBlock(_component_ImgPreview, {
          key: item.id,
          col: "col-3",
          url: item.url,
          callback: () => $setup.deleteBg(item.id)
        }, null, 8, ["url", "callback"]))
      }), 128)),
      ($setup.settingsStoreState.bg.isLoading)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            isOverlay: ""
          }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}